<template>
  <div class="main-content" id="content">
    <h3 class="marginBottom20">
      {{
        `${
          type === "add" ? "新增" : type === "edit" ? "编辑" : "查看"
        }SEA二维码`
      }}
    </h3>
    <el-form
      ref="form"
      :model="form"
      label-position="left"
      label-width="80px"
      :rules="rules"
    >
      <el-form-item label="方案名称" prop="name">
        <el-input
          v-model="form.name"
          placeholder="请输入"
          style="width: 400px"
          maxlength="50"
          :disabled="disabled"
        ></el-input>
      </el-form-item>
      <el-form-item label="文件" prop="fileUrl">
        <FormFileUpload
          :url="form.fileUrl"
          @upload="upload"
          :disabled="disabled"
          :limit="1"
          :maxSize="500"
          :accept="'.pdf'"
          :multiple="true"
          :isFileName="'上传成功'"
          :showView="true"
        />
        <div style="color: #c0c1c3">文件格式：.pdf</div>
      </el-form-item>

      <el-form-item>
        <el-button @click="revert">返回</el-button>
        <el-button type="primary" @click="submit" v-if="!disabled"
          >确认</el-button
        >
      </el-form-item>
    </el-form>
    <!-- </el-card> -->
  </div>
</template>

<script>
import { infoSeaUpdate, infoSeaView } from "@/api/info/sea";
import FormEditor from "@/components/formComponents/formEditor";
import dayjs from "dayjs";
import FormFileUpload from "@/components/formComponents/formFileUpload";
import FormImgUpload from "@/components/formComponents/formImgUpload";

export default {
  name: "create",
  components: {
    FormEditor,
    FormFileUpload,
    FormImgUpload,
  },
  data() {
    return {
      classifyList: [],
      id: this.$route.query.id,
      disabled: this.$route.query.type === "view",
      type: this.$route.query.type,
      form: {
        name: "",
        fileUrl: [],
        fileName: "",
      },
      //校验规则
      rules: {
        name: [{ required: true, message: "请输入", trigger: "blur" }],
        fileUrl: [
          {
            required: true,
            message: "请上传",
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              if (value.length === 0) {
                callback(new Error("请上传"));
              } else {
                callback();
              }
            },
          },
        ],
      },
      loadingSubmit: false,
    };
  },
  mounted() {
    if (this.id) {
      this.getView();
    }
  },
  methods: {
    async getView() {
      if (this.id) {
        const { data } = await infoSeaView(this.id);
        this.form = {
          ...data,
          fileUrl: data.fileUrl.split(","),
        };
      }
    },

    async submit() {
      if (this.loadingSubmit) {
        return;
      }
      let newVal = {
        ...this.form,
        fileUrl: this.form.fileUrl.join(","),
      };

      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loadingSubmit = true;
          infoSeaUpdate({
            ...newVal,
            id: this.id,
          })
            .then((res) => {
              // 获取数据成功后的其他操作
              if (res.code == 200) {
                this.loadingSubmit = false;
                this.$router.go(-1);
              } else {
                this.loadingSubmit = false;
                this.$message.error(res.msg);
              }
            })
            .catch((err) => {
              // loading.close();
              // this.$message.error(err.msg);
              this.loadingSubmit = false;
            });
        }
      });
    },
    //富文本框变化替换值
    editoChange(e) {
      this.form.content = e;
    },
    //文件上传
    upload(e, name) {
      if (Array.isArray(e)) {
        this.form.fileUrl = [...e];
      } else {
        this.form.fileUrl = [...this.form.fileUrl, e];
      }
      this.form.fileName = name;
    },
    revert() {
      this.$router.go(-1);
    },
    // 切换类型
    getType(e) {
      if (e == 1) {
        this.form.content = "";
      } else {
        this.form.content = [];
      }
    },
  },
};
</script>

<style scoped>
.main-content {
  padding-left: 30px;
  padding-right: 40%;
}
</style>
